<template>
  <TTView>
    <VRow>
      <VCol>
        <PositionForm
          :entity="position"
          :companies="companies"
          @update:name="position.name = $event"
          @update:companyId="position.companyId = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import { DEFAULT_POSITION } from '../../components/competency-matrix/positions/common';
import PositionForm from '../../components/competency-matrix/positions/PositionForm.vue';

export default {
  name: 'PositionEdit',

  components: {
    PositionForm,
  },

  data() {
    return {
      companies: [],
      loading: false,
      position: { ...DEFAULT_POSITION },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        const { positionId: id } = this.$route.params;
        const data = { id };
        const [companiesResponse, positionResponse] = await Promise.all([
          this.$di.api.Account.indexCompany(),
          this.$di.api.CompetencyMatrix.PositionsGet(data),
        ]);

        this.companies = companiesResponse.companies || [];
        this.position = positionResponse.position || { ...DEFAULT_POSITION };
      } catch (err) {
        this.$di.notify.errorHandler(err);
        this.$di.redirect.errorHandler404(err);
      } finally {
        // no-finally
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;
        const { position } = this;
        await this.$di.api.CompetencyMatrix.PositionsUpdate({ position });
        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
